.truncated {
  :global(.ql-bubble .ql-editor) {
    /*
    only show the first 5 lines of the note
    */
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    display: -webkit-box;
  }
}

:global(.ql-bubble .ql-editor) {
  padding: 10px 0 0;
}
