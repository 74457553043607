.preview_unav {
  width: 100%;
  height: auto !important;
  padding: 20px;
  margin: auto !important;
  text-align: center;
}

.preview_unav {
  text-align: center;
  margin: auto;
}

.preview-unavailable {
  cursor: pointer;
  max-width: 400px;
  display: flex;
  padding: 15px 20px;
  margin: 10px 0;
  align-items: center;
  background: var(--color-surface-400);
  border-radius: 5px;
}

.preview-thumb {
  white-space: nowrap;
  overflow: hidden;
  min-width: 32px;
  width: 32px;
  height: 32px;
  margin: 4px 10px 0 0;
}

.preview-unavailable_atr {
  overflow: hidden;
  color: var(--primary-font-color);

  .preview-unavailable_name {
    font-size: 18px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis; //white-space: nowrap;
    word-break: break-all;
  }

  .preview-unavailable_more {
    align-self: center;
    color: var(--secondary-font-color);
  }
}
