@import '../../../assets/css/design/utilities/text';

.workspaceButton {
  height: 65px;
  width: 100%;
  position: relative;
  flex: 1 1 auto;
}

.info {
  display: flex;
  flex-grow: 1;
  min-width: 0;
  align-items: center;
}
.name {
  @include text-ellipsis();
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;
}
.badge {
  display: flex;
  margin-right: 5px;
  margin-left: auto;
}

.dragHandle {
  margin-left: auto;
  cursor: move;
}

.email {
  font-weight: 400;
}

.icon {
  position: relative;
}

.meetingBeacon {
  bottom: -3px;
  right: -4px;
  z-index: 100;
  position: absolute;
  display: flex;
}

.draftIcon {
  bottom: -3px;
  left: -4px;
  z-index: 100;
  position: absolute;
  display: flex;
}

.unreadBadge {
  top: -4px;
  right: -7px;
  z-index: 100;
  position: absolute;
  display: flex;
}
