.fullScreenPopup {
  width: 400px;
  padding: 12px;
}
.wide {
  width: 600px;
}
.scroller {
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.header {
  margin-bottom: 20px;
}
