.text {
  white-space: pre-line;
  /*
  only show the first 5 lines of the note
  */
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  display: -webkit-box;
}
